#content-container{
	display: flex;
	flex-direction: row;
}
#content{
	height: 100%;
	min-height: 500px;
}
.for-container{
	height: 400px;
}
.right-button{
text-align:right;
}