.avatar-uploader{
	align-items: center;
	display: flex;
	flex-direction: column;
}
.avatar{
	height: 100px;
	width: 100px;
}
.avatar-uploader > .ant-upload {
	height: 128px;
	width: 128px;
}
#username{
	font-weight: bolder;
	font-size: 50px;
	text-align: center;
}
#information{
	margin: 120px;
}