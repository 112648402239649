.logo {
	height: 60px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: left;
	justify-content: space-around;
	margin-bottom: 10%;
}

.logo_img {
	height: 100%;
	width: auto;
	margin-left: 10px;
	margin-right: 0;
}
.ant-alert-error{
	width: 300px;
	margin: 20px;
}
.form-forgot {
	float: right;
}
.form-button {
	width: 400px;
}

.root{
	padding: 20px 6% 20px 6%;
	background: #fff;
	min-Height: 700px;
	align-items: center;
	font-size: 18px;
}

header > div{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

header > div > div{
	textAlign: center;
	line-height: normal;
	display: flex;
	align-items: center;
}

.subtitle{
	font-weight: bold;
	margin-bottom: 10px;
}
h1 {
    font-size: 2em;
}
.noSelect{
	moz-user-select: -moz-none;
	-moz-user-select: none;
	-o-user-select:none;
	-khtml-user-select:none;
	-webkit-user-select:none;
	-ms-user-select:none;
	user-select:none;
}
