.markdownContainer {
	position: relative;
	width: 94%;
	margin-left: 3%;
	margin-top: 20px;
}
.logo_inside {
	width: 150px;
	margin-left: 20px;
	margin-top: 20px;
}
