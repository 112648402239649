.container {
    position: relative;
    height: calc(80vh - 67px - 48px);
    width: 80%;
}
  
.center {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
  
.image {
    max-width: 75%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
  
.background {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    filter: opacity(0.7) blur(12px);
}

.background_img {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
}
  
.description {
    position: absolute;
    left: 48px;
    bottom: 24px;
    text-shadow: 0px 0px 12px white;
}